import React, { useEffect, useState } from 'react';
import './Services.css';

function Services() {
  const [recoveryCases, setRecoveryCases] = useState(0);
  const [yearsOfService, setYearsOfService] = useState(0);
  const [recoveredAssets, setRecoveredAssets] = useState(0);

  const animateValue = (setValue, start, end, duration) => {
    const stepTime = Math.abs(Math.floor(duration / (end - start)));
    let current = start;
    const increment = end > start ? 1 : -1;

    const timer = setInterval(() => {
      current += increment;
      setValue(current);
      if (current === end) {
        clearInterval(timer);
      }
    }, stepTime);
  };

  useEffect(() => {
    animateValue(setRecoveryCases, 0, 9000, 2000);
    animateValue(setYearsOfService, 0, 12, 1500);
    animateValue(setRecoveredAssets, 0, 790, 2500);
  }, []);

  const servicesList = [
    { title: "Social Media Hacking", description: "Recover or monitor locked accounts with discretion to ensure your privacy." },
    { title: "Private Investigator Services", description: "In-depth background checks and surveillance to provide critical insights." },
    { title: "Website & Database Hacking", description: "Identify vulnerabilities, restore access, and secure compromised sites." },
    { title: "Phone Number/Email Tracking", description: "Track numbers or emails to identify sources, preventing fraud and securing data." },
    { title: "Phone Hacking & Remote Access", description: "Retrieve lost data or monitor devices with secure, discreet remote access." },
    { title: "Email Hacking & Recovery", description: "Regain access to compromised emails, enhance security, and trace breaches." }
  ];

  return (
    <section id="services" className="services">
      <div className="service-cards">
        <div className="services-card">
          <h3 className="highlighted-number">{recoveryCases.toLocaleString()}</h3>
          <p>Recovery Cases</p>
        </div>
        <img width="30" height="60" src="https://img.icons8.com/ios-glyphs/30/vertical-line.png" alt="vertical-line"/>
        <div className="services-card">
          <h3 className="highlighted-number">{yearsOfService}+</h3>
          <p>Years Of Service</p>
        </div>
        <img width="30" height="60" src="https://img.icons8.com/ios-glyphs/30/vertical-line.png" alt="vertical-line"/>
        <div className="services-card">
          <h3 className="highlighted-number">${recoveredAssets}M</h3>
          <p>Worth Of Recovered Assets</p>
        </div>
        <img width="30" height="60" src="https://img.icons8.com/ios-glyphs/30/vertical-line.png" alt="vertical-line"/>
      </div>

      <div className="services-include">
        <h1>Our Services Include</h1>
        <img width="290" height="30" src="https://img.icons8.com/officel/80/horizontal-line.png" alt="horizontal-line" className='black-line'/>
      </div>

      <div className="service-details">
        {servicesList.map((service, index) => (
          <div className="service-card" key={index}>
            <h3 className='service-title'>{service.title}</h3>
            <p className="card-description">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
