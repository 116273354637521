import React, { useEffect } from 'react';
import './Footer.css';
import feather from 'feather-icons';

function Footer() {
  useEffect(() => {
    feather.replace();
  }, []);

  return (
    <footer className="footer">
      <div className="footer-grid">
        <h1>Cyber Tech Solutions</h1>
        <div className="social-logo">
          <a href="https://www.youtube.com"><i data-feather="youtube"></i></a>
          <a href="https://www.facebook.com"><i data-feather="facebook"></i></a>
          <a href="https://www.instagram.com"><i data-feather="instagram"></i></a>
        </div>
      </div>

      <div className="footer-columns">
        <div className="footer-column">
          <p>Our Services</p>
          <p className="footer-item">Services</p>
          <p className="footer-item">About Us</p>
          <p className="footer-item">Contact</p>
          <p className="footer-item">Privacy Policy</p>
        </div>
        <div className="footer-column">
          <p>Follow Us</p>
          <p className="footer-item">YouTube</p>
          <p className="footer-item">Facebook</p>
          <p className="footer-item">Instagram</p>
        </div>
        <div className="footer-column">
          <p>Contact</p>
          <p className="footer-item">help@cybertechsolutions.pro</p>
          <p className="footer-item">Phone: +1 (520) 254-5100</p>
        </div>
      </div>

      <div className="footer-bottom-bar">
        <p>© {new Date().getFullYear()} Cyber Tech Solutions. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
