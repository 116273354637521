import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [messageSent, setMessageSent] = useState(false); 
  const [error, setError] = useState(""); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_canohcr",
        "template_6hjlfmz",
        {
          to_name: "CyberTech Solutions Support", 
          from_name: formData.name, 
          from_email: formData.email, 
          message: formData.message, 
        },
        "i0WSerYslekVDziuq"
      )
      .then(
        (response) => {
          console.log(
            "Message sent successfully!",
            response.status,
            response.text
          );
          setMessageSent(true);
          setFormData({ name: "", email: "", message: "" });
          setError("");
        },
        (error) => {
          console.error("Failed to send message:", error);
          setError("Failed to send message. Please try again.");
          setMessageSent(false);
        }
      );
  };

  return (
    <section className="contact" id="contact">
      <h2>Contact Us</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <button type="submit">Submit</button>
      </form>
      {messageSent && (
        <p className="success-message">
          Message sent successfully! A cybersecurity specialist is now assigned to your case and will review your details shortly.
        </p>
      )}
      {error && <p className="error-message">{error}</p>}
    </section>
  );
}

export default Contact;
