import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import './Hero.css';

function Hero() {
  const services = [
    "Recovery Of Assets",
    "Private Investigations",
    "Professional Hacking Solutions",
    "Website Data Interception"
  ];

  const [currentService, setCurrentService] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentService((prevService) =>
        prevService === services.length - 1 ? 0 : prevService + 1
      );
    }, 3000); 

    return () => clearInterval(interval);
  }, [services.length]);

  return (
    <section className="hero">
      <div className="hero-content">
        <h1>You are Welcome to</h1>
        <h2>Cyber Tech Solutions</h2>
        <p className='dynamic-text'>We help in <span className="dynamics-text">{services[currentService]}</span></p>
        <p className="hero-description">
          At Cyber Tech Solutions, we make it simple to get the expert help you need. Whether you're dealing with cyber threats or financial fraud, our global network of skilled hackers is ready to assist. Don't let uncertainty hold you back, reach out to us, and discover the specialized hacking solutions that can solve your problem.
          <br />
          From asset recovery to private investigations and professional hacking services, we're here to provide top-tier assistance. Contact us today and take the first step towards resolving your case.
        </p>
        <Link to="/contact" className="cta-button">Consult with an Expert</Link>
      </div>
    </section>
  );
}

export default Hero;
