import React from 'react';
import './Certification.css';
import cert1 from '../assets/maxresdefault.jpg'; 
import cert2 from '../assets/OIP (3).jpg';
import cert3 from '../assets/golden-iso-27001-certified-badge-or-information-security-management-system-iso-27001-icon-rubber-stamp-seal-label-emblem-with-check-mark-glossy-and-golden-badge-transparent-png.png';

import cert4 from '../assets/securityplus-logo.png';

import cert5 from '../assets/CISA_logo_4C.png';

import cert6 from '../assets/CISSP-Certified-Information-Systems-Security-Professional.jpg';

function Certification() {
  return (
    <section className="certification">
      <h1>We are certified professionals</h1>
      <p>
        Our clients trust us because we operate with the highest standard of integrity, bringing extensive industry knowledge and expertise to every claim. With a commitment to transparency, our contingency agreements clearly outline expectations, providing legal security and the assurance that we are dedicated partners in the recovery process.
      </p>
      <div className="certification-logos">
        <img src={cert1} alt="Certification 1" />
        <img src={cert2} alt="Certification 2" />
        <img src={cert3} alt="Certification 3" />

        <img src={cert4} alt="Certification 4" />
  
        <img src={cert5} alt="Certification 5" />

        <img src={cert6} alt="Certification 5" />
      </div>
    </section>
  );
}

export default Certification;
