import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import Hero from './Components/Hero';
import Services from './Components/Services';
import Testimonials from './Components/Testimonials';
import Certification from './Components/Certification';
import Footer from './Components/Footer';
import Contact from './Components/Contact';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <Services />
              <Testimonials />
              <Certification />
              <Footer />
            </>
          }
        />
        <Route 
          path="/contact" 
          element={
            <>
              <Contact />
              <Footer />  
            </>
          } 
        />
      </Routes>
    </div>
  );
}

export default App;
