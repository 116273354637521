import React from 'react';
import { useNavigate } from 'react-router-dom';  
import './Header.css';
import logo from '../assets/Preview.png';

function Header() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');  
  };

  const handleContactClick = () => {
    navigate('/contact');  
  };

  return (
    <header className="header">
      <div className="logo-container" onClick={handleLogoClick}>  
        <img src={logo} alt="Logo" className="logo-image" />
        <div className="logo-text">Cyber Tech Solutions</div>
      </div>
      <nav className="nav">
        <ul>
          <li><a href="#services">Services</a></li>
          <li><a href="#testimonials">Testimonials</a></li>
          <li><button className="nav-link" onClick={handleContactClick}>Contact</button></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
