import React from 'react';
import './Testimonials.css';
import clientImage1 from '../assets/OIP (1).jpg';
import clientImage2 from '../assets/OIP (2).jpg';
import clientImage3 from '../assets/R.jpg';


function Testimonials() {
  return (
    <section id="testimonials" className="testimonials">
       <h1>Testimonials</h1>
       <img className='hori-line' width="190" height="80" src="https://img.icons8.com/officel/80/horizontal-line.png" alt="horizontal-line"/>
      <h2>What our Clients have to say about us</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="carousel slide" data-ride="carousel" id="quote-carousel">
              {/* Carousel Slides / Quotes */}
              <div className="carousel-inner text-center">
                {/* Quote 1 */}
                <div className="carousel-item active">
                  <blockquote>
                    <div className="row">
                      <div className="col-sm-8 offset-sm-2">
                        <p>I was blown away by the speed and efficiency of Cyber Tech Solutions! They helped me recover my stolen Bitcoin in no time. Their team is knowledgeable and professional,I couldn’t have asked for better service.</p>
                        <small>Maria Volkova</small>
                      </div>
                    </div>
                  </blockquote>
                </div>
                {/* Quote 2 */}
                <div className="carousel-item">
                  <blockquote>
                    <div className="row">
                      <div className="col-sm-8 offset-sm-2">
                        <p>Cyber Tech Solutions provided excellent support when I was facing a major security breach. Their ethical approach and commitment to client confidentiality gave me peace of mind. Highly recommended for anyone needing hacking assistance!</p>
                        <small>Sophia Green</small>
                      </div>
                    </div>
                  </blockquote>
                </div>
                {/* Quote 3 */}
                <div className="carousel-item">
                  <blockquote>
                    <div className="row">
                      <div className="col-sm-8 offset-sm-2">
                        <p>I had a fantastic experience with Cyber Tech Solutions. They were able to locate and recover my lost social media accounts quickly. The staff was friendly and professional throughout the entire process. I can't thank them enough!</p>
                        <small>David Johnson</small>
                      </div>
                    </div>
                  </blockquote>
                </div>
                 {/* Quote 4 */}

                 <div className="carousel-item">
                  <blockquote>
                    <div className="row">
                      <div className="col-sm-8 offset-sm-2">
                        <p>I can’t recommend Cyber Tech Solutions enough! They helped me regain access to my hacked email account and guided me through securing my information afterward. Their expertise is unmatched in the industry.</p>
                        <small>Michael Wright</small>
                      </div>
                    </div>
                  </blockquote>
                </div>

  {/* Quote 5 */}

                <div className="carousel-item">
                  <blockquote>
                    <div className="row">
                      <div className="col-sm-8 offset-sm-2">
                        <p>Cyber Tech Solutions is the real deal! I had some concerns about online privacy, and their team provided invaluable advice and services. They truly care about their clients, and I felt supported every step of the way.</p>
                        <small>Emily Brooks</small>
                      </div>
                    </div>
                  </blockquote>
                </div>

                  {/* Quote 6 */}

                  <div className="carousel-item">
                  <blockquote>
                    <div className="row">
                      <div className="col-sm-8 offset-sm-2">
                        <p>The team at Cyber Tech Solutions saved my business from a potential disaster. They expertly handled a data breach and provided ongoing support to enhance my cybersecurity. I trust them completely with all my digital security needs.</p>
                        <small>James Carter</small>
                      </div>
                    </div>
                  </blockquote>
                </div>

              </div>

              {/* Bottom Carousel Indicators */}
              <ol className="carousel-indicators">
                <li data-target="#quote-carousel" data-slide-to="0" className="active">
                  <img className="img-responsive" src={clientImage1} alt="Client 1" />
                </li>
                <li data-target="#quote-carousel" data-slide-to="1">
                  <img className="img-responsive" src={clientImage2} alt="Client 2" />
                </li>
                <li data-target="#quote-carousel" data-slide-to="2">
                  <img className="img-responsive" src={clientImage3} alt="Client 3" />
                </li>
                <li data-target="#quote-carousel" data-slide-to="2">
                  <img className="img-responsive" src={clientImage3} alt="Client 3" />
                </li>
                <li data-target="#quote-carousel" data-slide-to="2">
                  <img className="img-responsive" src={clientImage3} alt="Client 3" />
                </li>
                <li data-target="#quote-carousel" data-slide-to="2">
                  <img className="img-responsive" src={clientImage3} alt="Client 3" />
                </li>
              </ol>


              {/* Carousel Buttons Next/Prev */}
              <a className="carousel-control-prev" href="#quote-carousel" role="button" data-slide="prev">
                <i className="fa fa-chevron-left"></i>
              </a>
              <a className="carousel-control-next" href="#quote-carousel" role="button" data-slide="next">
                <i className="fa fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
